// Add your JS customizations here
jQuery(function () {
    'use strict';

    jQuery(window).on('load scroll', function() {
        var header = jQuery('header#header');
        var scrollPosition = jQuery(window).scrollTop();

        if (scrollPosition > 10) { // Adjust the scroll position threshold as needed
        header.addClass('active');
        } else {
        header.removeClass('active');
        }
    });

    var slider = UIkit.slider('.logo-carousel div[uk-slider]');
    jQuery('.logo-carousel .slider-prev').click(function(e) {
        e.preventDefault();
        slider.show(slider.index - 1);
    });
    jQuery('.logo-carousel .slider-next').click(function(e) {
        e.preventDefault();
        slider.show(slider.index + 1);
    });

    var blog_slider = UIkit.slider('.blog-feed-carousel div[uk-slider]');
    jQuery('.blog-feed-carousel .slider-prev').click(function(e) {
        e.preventDefault();
        blog_slider.show(blog_slider.index - 1);
    });
    jQuery('.blog-feed-carousel .slider-next').click(function(e) {
        e.preventDefault();
        blog_slider.show(blog_slider.index + 1);
    });

    var product_slider = UIkit.slider('.featured-product-carousel div[uk-slider]');
    jQuery('.featured-product-carousel .slider-prev').click(function(e) {
        e.preventDefault();
        product_slider.show(product_slider.index - 1);
    });
    jQuery('.featured-product-carousel .slider-next').click(function(e) {
        e.preventDefault();
        product_slider.show(product_slider.index + 1);
    });

    jQuery('#category-filter').change(function() {
        var queryString = 'category_filter='+jQuery(this).val();

        // Get the current URL
        var currentUrl = window.location.href;

        // Check if the URL already has query parameters
        if (currentUrl.indexOf('?') > -1) {
            // If the URL already has query parameters, append the new query string with &
            currentUrl += '&' + queryString;
        } else {
            // If the URL does not have query parameters, append the new query string with ?
            currentUrl += '?' + queryString;
        }

        window.location.href = currentUrl;
    });

    jQuery(function($) {
        $('.woocommerce-pagination .page-numbers.current').parent('li').addClass('current');
    });

    // request-a-quote modal toggle
    jQuery(".button").each(function() {
        if (jQuery(this).attr('href') == '#request-a-quote') {
            jQuery(this).attr('uk-toggle', '');
        }
    });

    // get-in-touch modal toggle
    jQuery(".button").each(function() {
        if (jQuery(this).attr('href') == '#get-in-touch') {
            jQuery(this).attr('uk-toggle', '');
        }
    });
    
    jQuery(".main-menu ul li.menu-item-72 > a").click(function() {
        var url = jQuery(this).attr('href');
        window.location.href = url;
    });

    jQuery(".main-menu ul li.menu-item-1581 > a").click(function() {
        var url = jQuery(this).attr('href');
        window.location.href = url;
    });

    jQuery(".hero-banner").next('section').attr('id', 'next-section');

    jQuery(document).ready(function($) {
        jQuery('input[type="checkbox"]').change(function() {
            var label = jQuery(this).parent('label');
            if (jQuery(this).is(':checked')) {
                label.addClass('checked-label');
            } else {
                label.removeClass('checked-label');
            }
        });
    });

    jQuery('#copy-btn').click(function(){
        // Select the text field
        var copyText = jQuery('#copy-link');

        // Select the text inside the text field
        copyText.select();
        copyText[0].setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        document.execCommand("copy");

        // Change the button text to 'Copied!'
        var button = jQuery(this);
        button.text('Copied!');

        // Set a delay to change the text back to 'Copy'
        setTimeout(function(){
            button.text('Copy');
        }, 1000);
    });

    document.addEventListener('scroll', function() {
        const scrollTop = window.pageYOffset; // Current scroll position
        const parallaxImages = document.querySelectorAll('.parallax-image'); // Select all elements with the class
    
        parallaxImages.forEach(function(parallaxImage) {
            // Get the position of the element relative to the viewport
            const elementTop = parallaxImage.getBoundingClientRect().top + scrollTop;
    
            // Calculate how far the element is from the top of the viewport
            const elementOffset = scrollTop - elementTop;
    
            // Adjust the translation amount based on the element's offset
            parallaxImage.style.transform = `translateY(${elementOffset * 0.5}px) translateX(-50%)`;
        });
    });

    jQuery(document).ready(function($){
        // $('#german-link').on('click', function(){
        //     window.location = $(this).attr('data-href');
        // });

        // $('#english-link').on('click', function(){
        //     window.location = $(this).attr('data-href');
        // });

        $('.language-link').on('click', function(){
            window.location = $(this).attr('data-href');
        });

        // $('.language-switcher-link').on('click', function(e){
        //     window.location.href = $(this).attr('href');

        //     $.ajax({
        //         url: ajaxurl,
        //         method: 'GET',
        //         success: function(response) {
        //             alert(response);
        //         }
        //     });
        // });

        $(window).on('hashchange',function(){ 
            window.location.reload(true); 
        });

        // alert($.cookie('googtrans'));

        // if ($.cookie('googtrans') == 'en/fr') {
        //     alert('test');
        //     var img_src = $('#current-language').find('img').attr('src');

        //     alert(img_src);
        // }

        // $("#language-switcher-reload").load(location.href + " #language-switcher-reload");
    });
});